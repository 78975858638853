<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_slots')" :isFilter="true" :isPrint="true"
                        @filter-div-status="filterStatus = !filterStatus" :isExpandAll="!expandAllStatus"
                        :isCollapseAll="expandAllStatus" :other-button="excelExportData" @downloadExcel="downloadExcel"
                        @expand-all="expandAll" @collapse-all="collapseAll"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('student_slots')" :isFilter="true"
                              @filter-div-status="filterStatus = !filterStatus"
                              :isExpandAll="!expandAllStatus" :isCollapseAll="expandAllStatus"
                              :other-button="excelExportData"
                              @downloadExcel="downloadExcel" @expand-all="expandAll" @collapse-all="collapseAll"/>
            </template>
            <div class="spinner over text-center" v-if="loading">
                <b-spinner label="Spinning" variant="primary"></b-spinner>
            </div>
            <div v-else>
                <!-- Info -->
                <div class="mb-2 d-flex">
                    <b-button variant="light" class="border w-40 h-40 mr-2"
                              :class="showExtraStudentInfo ? null : 'collapsed'"
                              :aria-expanded="showExtraStudentInfo ? 'true' : 'false'" aria-controls="collapse-4"
                              @click="showExtraStudentInfo = !showExtraStudentInfo">
                        <i class="ri-arrow-down-s-line" v-if="!showExtraStudentInfo"></i>
                        <i class="ri-arrow-up-s-line" v-if="showExtraStudentInfo"></i>
                    </b-button>
                    <div
                        class="flex-grow-1 bg-light w-40 h-40 border rounded-sm d-flex align-items-center pl-3 font-weight-bold">
                        {{ getObjectValue(student, 'student_number') }},
                        {{ getObjectValue(student, 'name') }}
                        {{ getObjectValue(student, 'surname') }}
                    </div>
                </div>
                <b-collapse id="collapse-4" v-model="showExtraStudentInfo">
                    <div class="border rounded-sm p-4 mb-4">
                        <b-row>
                            <b-col sm="4" lg="auto">
                                <b-form-group :label="$t('faculty')">
                                    <div class="label-as-input">
                                        {{ student.program }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col sm="4" lg="auto">
                                <b-form-group :label="$t('program')">
                                    <div class="label-as-input">
                                        {{ student.program }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col sm="4" lg="auto">
                                <b-form-group :label="$t('class')">
                                    <div class="label-as-input">{{ student.class_name }}</div>
                                </b-form-group>
                            </b-col>
                            <b-col sm="4" lg="auto">
                                <b-form-group :label="$t('registration_type')">
                                    <div class="label-as-input">
                                        {{ student.registration_type }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col sm="4" lg="auto">
                                <b-form-group :label="$t('registration_season')">
                                    <div class="label-as-input">
                                        {{ student.registration_season }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col sm="4" lg="auto">
                                <b-form-group :label="$t('number_of_semesters_studied')">
                                    <div class="label-as-input">
                                        {{ student.semester_count }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col sm="4" lg="auto">
                                <b-form-group :label="$t('student_status')">
                                    <div class="label-as-input">
                                        {{ student.student_status }}
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </b-collapse>
                <b-row class="infos">
                    <b-col cols="6" md="3">
                        <div class="bg-white border rounded-sm text-center p-2 mb-3"
                             v-b-popover.hover.bottom="$t('cgpa') + ': ' + student.graduate_cgpa">
                            <label>{{ $t('gno') }} </label>
                            <h3 style="margin-bottom: 5px;">{{ student.cgpa ? student.cgpa : '-' }}</h3>
                        </div>
                    </b-col>
                    <b-col cols="6" md="3">
                        <div class="bg-white border rounded-sm mb-3">
                            <div class="text-center p-2">
                                <label class="d-block d-lg-none">{{ $t('credit') }}</label>
                                <label class="d-none d-lg-block">{{ $t('completed_credit') }} /
                                    {{ $t('curriculum_credits') }}
                                </label>
                                <h3 class="mb-0">{{ student.completed_credits }} / {{ student.total_credits }}</h3>
                            </div>
                            <b-progress :value="student.completed_credits" :max="student.total_credits" height="5px"/>
                        </div>
                    </b-col>

                    <b-col cols="6" md="3">
                        <div class="bg-white border rounded-sm mb-3">
                            <div class="text-center p-2">
                                <label class="d-block d-lg-none">{{ $t('slot') }}</label>
                                <label class="d-none d-lg-block">{{ $t('completed_slots') }} / {{ $t('total_slots') }}
                                </label>
                                <h3 class="mb-0">{{ student.completed_slots }} / {{ student.total_slots }} </h3>
                            </div>
                            <b-progress :value="student.completed_slots" :max="student.total_slots" height="5px"/>
                        </div>
                    </b-col>
                </b-row>

                <!-- Filter -->
                <div class="filter" v-show="filterStatus">
                    <h6>{{ $t('filter1').toUpper() }}</h6>
                    <div class="border rounded p-4 pb-0 mb-4">
                        <b-row>
                            <b-col cols="6" md="4" lg="3">
                                <b-form-group v-slot="{ ariaDescribedby }" class="mb-1" :label="$t('slot_types')">
                                    <b-form-select v-model="filterSlotType" :options="filterSlotTypes"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6" md="4" lg="3">
                                <b-form-group v-slot="{ ariaDescribedby }" class="mb-1" :label="$t('status')">
                                    <b-form-select v-model="filterStatusType" :options="filterStatusTypes"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </div>

                <!-- Slots -->
                <h6>{{ $t('slots').toUpper() }}</h6>
                <b-table :empty-filtered-text="$t('no_result')" :empty-text="$t('no_result')" bordered responsive
                         :items="filteredSlots" :fields="slotsFields" :tbody-tr-class="rowClass" show-empty
                         class="mb-4 table-dropdown no-scrollbar border rounded" @row-clicked="onRowClicked">
                    <template #cell(detail)="data">
                        <div class="w-40 h-40 cursor-pointer d-flex justify-content-center align-items-center">
                            <i
                                :class="data.detailsShowing ? 'ri-arrow-up-s-line font-size-20' : 'ri-arrow-down-s-line font-size-20'"></i>
                        </div>
                    </template>
                    <template #cell(responsive)="data">
                        <b-table-simple class="table-bordered table-striped table-sm">
                            <b-tbody>
                                <tr>
                                    <td class="font-weight-bold width-100">{{ $t('semester').toUpper() }}</td>
                                    <td>{{ data.item.semester }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">{{ $t('slot_type').toUpper() }}</td>
                                    <td>
                                        <span v-if="data.item.slot_type">
                                            {{ data.item.slot_type.name }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">{{ $t('course_code').toUpper() }}</td>
                                    <td>
                                        <span>
                                            {{
                                                data.item.course && data.item.course.code ? data.item.course.code : '-'
                                            }}
                                            <div v-if="data.item.prerequisites">
                                                <span
                                                    class="badge badge-warning">Prerequisites</span> {{ data.item.prerequisites }}
                                            </div>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">{{ $t('course_name').toUpper() }}</td>
                                    <td>
                                        <span v-if="data.item.slot_type && data.item.slot_type.code == 'M'">
                                            {{ data.item.course ? data.item.course.name : '-' }}
                                        </span>
                                        <span v-else-if="data.item.slot_type && data.item.slot_type.code == 'P'">
                                            {{ data.item.course ? data.item.course.name : '-' }}
                                        </span>
                                        <span v-else>
                                            {{ data.item.pool ? data.item.pool.name : '-' }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">{{ $t('credit').toUpper() }}</td>
                                    <td>{{ data.item.credit }}</td>
                                </tr>

                                <tr>
                                    <td class="font-weight-bold">{{ $t('status').toUpper() }}</td>
                                    <td>
                                        <span v-if="data.item.slot_status">
                                            <b-badge variant="success" v-if="data.item.slot_status == 1">
                                                {{ $t('pass').toUpper() }}
                                            </b-badge>
                                            <b-badge variant="primary" v-else-if="data.item.slot_status == 2">
                                                {{ $t('currently_enrolled').toUpper() }}
                                            </b-badge>
                                            <b-badge variant="secondary" v-else-if="data.item.slot_status == 3">
                                                {{ $t('failed').toUpper() }}
                                            </b-badge>
                                            <b-badge variant="warning" v-else-if="data.item.slot_status == 4">
                                                {{ $t('not_enrolled_yet').toUpper() }}
                                            </b-badge>
                                            <b-badge variant="dark" v-else-if="data.item.slot_status == 5">
                                                {{ $t('withdrew').toUpper() }}
                                            </b-badge>
                                        </span>
                                    </td>
                                </tr>
                            </b-tbody>
                        </b-table-simple>
                    </template>
                    <template #cell(slot_type)="data">
                        <span v-if="data.item.slot_type">
                            {{ data.item.slot_type.name }}
                        </span>
                    </template>
                    <template #cell(course_code)="data">
                        <span>
                            {{ data.item.course && data.item.course.code ? data.item.course.code : '-' }}
                        </span>
                        <div v-if="data.item.prerequisites" class="mt-1">
                            <span class="badge badge-warning">Prerequisites</span> {{ data.item.prerequisites }}
                        </div>
                    </template>
                    <template #cell(course_name)="data">
                        <span v-if="data.item.slot_type && data.item.slot_type.code == 'M'">
                            {{ data.item.course ? data.item.course.name : '-' }}
                        </span>

                        <span v-else-if="data.item.slot_type && data.item.slot_type.code == 'P'">
                            {{ data.item.course ? data.item.course.name : '-' }}
                        </span>
                        <span v-else-if="data.item.slot_type &&
                          (data.item.slot_type.code == 'HC'||
                          data.item.slot_type.code == 'SC'||
                           data.item.slot_type.code == 'MC'||
                           data.item.slot_type.code == 'G')">
                        </span>

                        <span v-else>
                            {{ data.item.pool ? data.item.pool.name : '-' }}
                        </span>
                        <span v-if="data.item.slot && data.item.slot.note">
                           <div>
                                <strong>{{$t('note')}}:</strong> {{ data.item.slot.note }}
                           </div>
                        </span>

                    </template>
                    <template #cell(status)="data">
                        <span v-if="data.item.slot_status">
                            <b-badge variant="success" v-if="data.item.slot_status == 1">
                                {{ $t('pass').toUpper() }}
                            </b-badge>
                            <b-badge variant="primary" v-else-if="data.item.slot_status == 2">
                                {{ $t('currently_enrolled').toUpper() }}
                            </b-badge>
                            <b-badge variant="secondary" v-else-if="data.item.slot_status == 3">
                                {{ $t('failed').toUpper() }}
                            </b-badge>
                            <b-badge variant="warning" v-else-if="data.item.slot_status == 4">
                                {{ $t('not_enrolled_yet').toUpper() }}
                            </b-badge>
                            <b-badge variant="dark" v-else-if="data.item.slot_status == 5">
                                {{ $t('withdrew').toUpper() }}
                            </b-badge>
                        </span>
                    </template>
                    <template #row-details="data">
                        <div class="d-flex align-items-center">
                            <h6 class="ml-2 mr-3 mb-0 mt-0">{{ $t('courses').toUpper() }}</h6>
                        </div>
                        <div class="bg-white m-2" v-if="data.item.courses && data.item.courses.length > 0">
                            <b-table-simple class="table table-bordered m-0">
                                <b-thead>
                                    <b-th class="d-table-cell d-lg-none print">{{ $t('course').toUpper() }}</b-th>
                                    <b-th class="d-none d-lg-table-cell print">{{ $t('academic_year').toUpper() }} / {{
                                            $t('period').toUpper()
                                        }}
                                    </b-th>
                                    <b-th class="d-none d-lg-table-cell print">{{ $t('course_code').toUpper() }}</b-th>
                                    <b-th class="d-none d-lg-table-cell print">{{ $t('course_name').toUpper() }}</b-th>
                                    <b-th class="d-none d-lg-table-cell print text-center">{{
                                            $t('letter_grade').toUpper()
                                        }}
                                    </b-th>
                                    <b-th class="d-none d-lg-table-cell text-center">{{
                                            $t('explanation').toUpper()
                                        }}
                                    </b-th>
                                    <b-th class="d-none d-lg-table-cell print text-center">{{
                                            $t('credit').toUpper()
                                        }}
                                    </b-th>
                                    <!-- <b-th class="d-none d-lg-table-cell print text-center">{{ $t('ects').toUpper() }}</b-th>
                                    <b-th class="d-none d-lg-table-cell print text-center">{{ $t('notexcredit').toUpper()
                                    }}</b-th> -->
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="(course, courseKey) in data.item.courses" :key="'course' + course.id">
                                        <b-td class="d-table-cell d-lg-none print">
                                            <b-table-simple class="table-bordered table-striped table-sm">
                                                <b-tbody>
                                                    <tr>
                                                        <td class="font-weight-bold width-100">{{
                                                                $t('academic_year').toUpper()
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{
                                                                course.semester && course.semester.academic_year ?
                                                                    course.semester.academic_year : '-'
                                                            }}
                                                            /
                                                            {{
                                                                course.semester && course.semester.semester ?
                                                                    getSemester(course.semester.semester) : '-'
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold width-100">{{
                                                                $t('course_code').toUpper()
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{ course.course_code || '-' }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold width-100">{{
                                                                $t('course_name').toUpper()
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{ course.course_name || '-' }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold width-100">{{
                                                                $t('letter_grade').toUpper()
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{ course.letter_grade ? course.letter_grade : '-' }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold width-100">
                                                            {{ $t('explanation').toUpper() }}
                                                        </td>
                                                        <td>
                                                            {{
                                                                course.explanation || '-'
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold width-100">{{
                                                                $t('credit').toUpper()
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{ course.credit ? course.credit : 0 }}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="font-weight-bold width-100">{{
                                                                $t('notexcredit').toUpper()
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{ parseFloat(course.point * course.credit).toFixed(2) }}
                                                        </td>
                                                    </tr>
                                                </b-tbody>
                                            </b-table-simple>
                                        </b-td>
                                        <b-td class="d-none d-lg-table-cell print align-middle">
                                            {{
                                                course.semester && course.semester.academic_year ?
                                                    course.semester.academic_year : '-'
                                            }}
                                            /
                                            {{
                                                course.semester && course.semester.semester ?
                                                    getSemester(course.semester.semester) : '-'
                                            }}
                                        </b-td>
                                        <b-td class="d-none d-lg-table-cell print align-middle">
                                            {{ course.course_code || '-' }}
                                        </b-td>
                                        <b-td class="d-none d-lg-table-cell print align-middle">
                                            {{ course.course_name || '-' }}
                                        </b-td>
                                        <b-td class="d-none d-lg-table-cell print align-middle text-center">
                                            {{ course.letter_grade ? course.letter_grade : '-' }}
                                        </b-td>
                                        <b-td class="d-none d-lg-table-cell align-middle text-center"
                                              style="word-break: break-word;white-space: normal;max-width: 200px"
                                        >
                                            {{ course.explanation ? course.explanation : '-' }}
                                        </b-td>
                                        <b-td class="d-none d-lg-table-cell print align-middle text-center">
                                            {{ course.credit ? course.credit : 0 }}
                                        </b-td>
                                        <!-- <b-td class="d-none d-lg-table-cell print align-middle text-center">
                                            {{ parseFloat(course.point * course.credit).toFixed(2) }}
                                        </b-td> -->
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                        <div class="border bg-white rounded-sm p-4 m-2" v-else>
                            {{ $t('no_course') }}
                        </div>
                    </template>
                </b-table>
            </div>

        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Services
import StudentProgramService from '@/services/StudentProgramService'
import StudentSlotService from '@/services/StudentSlotService'

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
    },
    metaInfo() {
        return {
            title: this.$t('student_slots')
        }
    },
    data() {
        return {
            // Loading
            loading: false,
            expandAllStatus: false,

            // Visible
            showExtraStudentInfo: false,

            // Student
            studentProgramId: null,
            studentProgramCode: null,
            student: {},
            studentCourseForm: null,

            // Filters
            filterStatus: false,
            filterSlotType: null,
            filterSlotTypes: [],
            filterStatusType: null,
            filterStatusTypes: [
                {value: null, text: this.$t('all').toUpper()},
                {value: 1, text: this.$t('success').toUpper()},
                {value: 2, text: this.$t('currently_enrolled').toUpper()},
                {value: 3, text: this.$t('failed').toUpper()},
                {value: 4, text: this.$t('not_enrolled_yet').toUpper()},
                {value: 5, text: this.$t('withdrew').toUpper()}
            ],

            // Slots
            slots: [],
            slotsFields: [
                {
                    key: 'responsive',
                    label: this.toUpperCase('slot'),
                    thClass: 'd-table-cell d-lg-none print',
                    tdClass: 'd-table-cell d-lg-none print'
                },
                {
                    key: 'semester',
                    label: this.toUpperCase('curriculum') + ' ' + this.toUpperCase('semester'),
                    thClass: 'd-none d-lg-table-cell print text-center',
                    tdClass: 'd-none d-lg-table-cell print align-middle text-center'
                },
                {
                    key: 'slot_type',
                    label: this.toUpperCase('slot_type'),
                    thClass: 'd-none d-lg-table-cell print',
                    tdClass: 'd-none d-lg-table-cell print align-middle'
                },
                {
                    key: 'course_code',
                    label: this.toUpperCase('course_code'),
                    thClass: 'd-none d-lg-table-cell print',
                    tdClass: 'd-none d-lg-table-cell print align-middle'
                },
                {
                    key: 'course_name',
                    label: this.toUpperCase('course_name'),
                    thClass: 'd-none d-lg-table-cell print',
                    tdClass: 'd-none d-lg-table-cell print align-middle'
                },
                {
                    key: 'credit',
                    label: this.toUpperCase('credit'),
                    thClass: 'd-none d-lg-table-cell print text-center',
                    tdClass: 'd-none d-lg-table-cell print text-center align-middle'
                },

                {
                    key: 'status',
                    label: this.toUpperCase('status'),
                    thClass: 'd-none d-lg-table-cell print text-center',
                    tdClass: 'd-none d-lg-table-cell print text-center'
                },
                {
                    key: 'detail',
                    label: ' ',
                    class: this.moveMode ? 'width-150 p-1 align-middle' : 'w-40 p-1 align-middle'
                }
            ],
            slotId: null,

            // Select & Detail
            slotsSelectIndex: null,
            slotsDetailsRow: null,
            excelExportData: {
                title: 'EXCEL',
                show: true,
                icon: 'ri-download-2-line',
                emit: 'downloadExcel'
            },
            slotTypeColorData: {}
        }
    },
    created() {
        this.studentProgramId = parseInt(this.$route.params.id)
        this.$store.dispatch("auth/initUser");
        let user = this.$store.getters['auth/getUser'];
        if (user.person.type == 'student') {
            this.studentProgramId = user.active_student_program.id
        }

        // Slots
        if (this.studentProgramId) {
            this.getSlots()
        } else {
            this.$router.push('/404');
        }
    },
    computed: {
        filteredSlots() {
            if (this.slots) {
                if (this.filterSlotType && this.filterStatusType) {
                    return this.slots.filter(item => {
                        return (item.slot_type.code.toLowerCase().indexOf(this.filterSlotType.toLowerCase()) > -1) && (item.slot_status == this.filterStatusType)
                    })
                }
                if (this.filterSlotType) {
                    return this.slots.filter(item => {
                        return item.slot_type.code.toLowerCase().indexOf(this.filterSlotType.toLowerCase()) > -1
                    })
                }
                if (this.filterStatusType) {
                    return this.slots.filter(item => {
                        return item.slot_status == this.filterStatusType
                    })
                }

                return this.slots;
            }
            return [];
        },

    },
    methods: {
        curriculumsTotal(value1, value2) {

            return value1 + value2

        },
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return
            }

            if (item.slot_status == 1) {
                return `table-slot-bg--green`
            } else if (item.slot_status == 2) {
                return `table-slot-bg--blue`
            } else if (item.slot_status == 3) {
                return `table-slot-bg--red`
            } else if (item.slot_status == 4) {
                return `table-slot-bg--orange`
            }
            else if (item.slot_status == 5) {
                return `table-slot-bg--yellow`
            }
        },
        onRowClicked(item, index, event) {
            this.$set(item, '_showDetails', !item._showDetails);
            this.slotsDetailsRow = item._showDetails ? item : false;
            this.slotsSelectIndex = item._showDetails ? index : null;
        },
        expandAll() {
            this.expandAllStatus = true
            for (const item of this.slots) {
                this.$set(item, '_showDetails', true)
            }
        },
        collapseAll() {
            this.expandAllStatus = false
            for (const item of this.slots) {
                this.$set(item, '_showDetails', false)
            }
        },
        async getSlots() {
            this.loading = true
            await StudentProgramService.studentSlots(this.studentProgramId)
                .then((response) => {
                    this.student = response.data.data.student
                    this.studentProgramCode = this.student.program_code
                    this.slots = response.data.data.slots
                    this.filterSlotTypes = [
                        {value: null, text: this.$t('all').toUpper()}
                    ]
                    Object.values(response.data.data.slot_types).forEach(item => {
                        this.filterSlotTypes.push({
                            value: item.code,
                            text: item.name.toUpper()
                        })
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        downloadExcel() {
            StudentSlotService.downloadAsExcel(this.studentProgramId)
                .then(res => this._downloadFile(res, this.$t('student_slots') + '.xlsx'))
                .catch(err => this.showErrors(err))
        }
    }
}
</script>
<style lang="scss">
.infos .progress {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.b-table-details {
    background: #f1f1f1 !important;
}

$tableColors: (
    0: #196ee6,
    1:#e81c1c,
    2:#f76307,
    3:#03fc52,
);
.table-slot-bg--green {
    background-color: #D9F2D0;
}

.table-slot-bg--orange {
    background-color: #FAE2D5;
}

.table-slot-bg--yellow {
    background-color: #faf4d5;
}

.table-slot-bg--red {
    background-color: #FF9393;
}

.table-slot-bg--blue {
    background-color: #DAE9F7;
}

//@each $colorname, $color in $tableColors {
//    .table-slot-bg--#{$colorname} {
//        background-color: rgba($color, $alpha: 0.3);
//        // border: thick double $color;
//        // border: 3px solid $color;
//        // border-bottom: 3px solid $color;
//    }
//}
</style>
